.appointment-details-page {
  height: 100%;
  padding: 30px 0;
  width: 90%;
  max-width: 960px;
  margin: 0 auto;
}
.appointment-details-page > .wheel-loader {
  margin-top: -30px;
}



.appointment-modal {
  transition: all 0.5s ease;
}
.appointment-modal.ant-drawer-open {
  z-index: 1100;
}
.appointment-modal.ant-drawer-open .ant-drawer-content-wrapper {
  width: calc(100vw - 300px) !important;
  transform: translateX(0px) !important;
}

@media (max-width: 991px) {
  .appointment-modal.ant-drawer-open .ant-drawer-content-wrapper {
    width: calc(100vw) !important;
    top: 65px;
  }
}

.appointment-modal .drawer-title {
  font-size: 24px;
  font-weight: 600;
  text-align: left;
  color: var(--mariner-color);
  margin-bottom: 0;
  width: 100%;
  max-width: 400px;
  line-height: 50px;
  white-space: nowrap;
}

@media (max-width: 1199px) {
  .appointment-modal .drawer-title {
    line-height: 38px;
    max-width: 300px;
  }

}

@media (max-width: 575px) {
  .appointment-modal .drawer-title {
    max-width: 300px;
  }
}

.appointment-modal .ant-form {
  padding: 0 !important;
}

.appointment-modal .info-title {
  margin: 0;
  font-weight: 600;
  color: var(--primary-color);
  font-size: 16px;
}
.appointment-modal .info-row {
  padding: 15px;
}
.appointment-modal .info-row .actions {
  display: flex;
  justify-content: flex-end;
}
.appointment-modal .info-row .actions .buttons-container {
  display: flex;
  gap: 10px;
}
.appointment-modal .arrow-down {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;

  border-top: 7px solid var(--primary-color);
}
.appointment-modal .mobile-spacing {
  margin-top: 12px;
  margin-bottom: 2px;
}
.appointment-modal .mobile-info {
  font-size: 16px;
}
.appointment-modal .ant-drawer-header {
  padding-left: 58px;
  padding-right: 58px;
  padding-bottom: 0px;
  background: #fff;
  border: none;
}
.appointment-modal .ant-drawer-body {
  padding-left: 61px;
  padding-right: 61px;
  padding-bottom: 0px;
  padding-top: 0px;
}

.ant-menu-horizontal {
  border-bottom: none !important;
}

.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  border-bottom: none !important;
  transition: none !important;
}

@media (min-width: 1200px) {
  .appointment-modal .ant-drawer-header .ant-drawer-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .appointment-modal .ant-drawer-header .ant-drawer-title {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
  }
}

@media (max-width: 991px) {
  .appointment-modal .ant-drawer-header .ant-drawer-title {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.appointment-modal .cancel_link {
  min-width: 90px;
  font-size: 16px;
  padding: 0;
}
.appointment-modal .appointment-options-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  width: 100%;
}

.appointment-modal .ant-drawer-content-wrapper .ant-drawer-body .section-title {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .appointment-modal
    .ant-drawer-content-wrapper
    .ant-drawer-body
    .section-title {
    margin-top: 10px;
  }
}

.appointment-modal
  .ant-drawer-content-wrapper
  .ant-drawer-body
  .ant-form-item-extra {
  font-weight: 600;
  text-align: left;
  color: #444444;
  font-family: "EMPrint", sans-serif;
}
.appointment-modal .warning {
  font-weight: 600;
  color: var(--mariner-color);
  font-size: 18px;
  margin-bottom: 10px;
}
.appointment-modal .edit-button {
  border: 0;
  padding: 0;
  color: var(--primary-color);
}
.appointment-modal .edit-button svg {
  height: 20px;
  width: 20px;
}
.appointment-modal .section-title-container {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.appointment-modal .ant-btn3 {
  width: 90% !important;
  margin-left: auto;
  margin-right: auto;
  display: block;
  height: 50px;
}
.appointment-modal .ant-btn span2 {
  font-weight: 600;
  font-size: 18px;
}
.appo-progress {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
}
.appo-progress h2 {
  font-size: 20px !important;
  width: 100%;
  margin: -25px 0px 30px 0px !important;
}


.ant-progress-bg {
  height: 1px !important;
}

.appointment-modal
  .select-align
  .ant-select-selector
  .ant-select-selection-item {
  text-align: start !important;
}
.appointment-modal
  .select-align
  .ant-select-selector
  .ant-select-selection-placeholder {
  text-align: start !important;
}
.appointment-modal .ant-form-item-explain-error {
  text-align: start !important;
}

@media only screen and (max-width: 992px) {
  
  .appointment-modal .ant-drawer-header {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0px;
    background: #fff;
    border: none;
  }
  .appointment-modal .ant-drawer-body {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0px;
    padding-top: 0px;
  }
}
