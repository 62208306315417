.add-workshop-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #fff;
}
.add-workshop-page .ant-layout {
  background: #fff !important;
}

/*=============================================
=         Process Steps and Container         =
=============================================*/
.add-workshop-page .process-steps {
  margin: 40px auto;
  width: 90%;
}
.add-workshop-page .process-steps .ant-steps {
  max-width: 768px;
  margin: 0 auto;
}
.add-workshop-page .step-process-container {
  margin: 0px auto 40px auto;
  width: 100%;
  display: block;
  position: relative;
  top: 0;
  left: 0;
}
.add-workshop-page .ant-steps-label-vertical .ant-steps-item-tail {
  padding-left: 16px;
  padding-right: 16px;
}
.add-workshop-page .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
  background: rgba(0, 0, 0, 0.4);
}

/*=============================================
=              General Carousel               =
=============================================*/
.add-workshop-page .step-process-container > .ant-carousel {
  width: 90%;
  margin: 0 auto;
}
.add-workshop-page .slick-slider.steps-content-carousel.slick-initialized > .slick-list > .slick-track > .slick-slide > div {
  padding: 0 10px;
}
.add-workshop-page .slick-slider.steps-content-carousel.slick-initialized > .slick-list > .slick-track > .slick-slide {
  overflow: hidden;
}
.add-workshop-page #workshop-schedule-form {
  margin: 0 !important;
}

/*========  End of General Carousel  =========*/

/*=========================================
=                Ant Steps                =
=========================================*/
.add-workshop-page .process-steps .ant-steps-item-content {
  margin: 10px 0 0 0 !important;
}
.add-workshop-page .process-steps .ant-steps-item-content .ant-steps-item-title {
  font-weight: 600;
  line-height: 1.2;
  font-size: 16px !important
}
.add-workshop-page .process-steps .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #444;
}
.add-workshop-page .process-steps .ant-steps-item-icon .ant-steps-icon {
  /* color: #fff; */
  top: -1px !important;
}

/*----------  Wait Step  ----------*/
.add-workshop-page .process-steps .ant-steps-item-wait .ant-steps-item-icon {
  border-color: var(--mariner-color);
}
.add-workshop-page .process-steps .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: var(--mariner-color);
}

/*----------  Current Step  ----------*/
.add-workshop-page .process-steps .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: var(--mariner-color) !important;
  border-color: var(--mariner-color) !important;
}

/*----------  Finished Step  ----------*/
.add-workshop-page .process-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: var(--mariner-color);
  border-color: var(--mariner-color);
}
.add-workshop-page .process-steps .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #fff !important;
}
.add-workshop-page .process-steps .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: var(--mariner-color);
}



/*=========================================
=               Ant Carousel              =
=========================================*/
.add-workshop-page .process-steps .ant-carousel {
  display: none;
}
.add-workshop-page .process-steps .carousel-step p {
  font-size: 16px !important;
  font-weight: 600;
  text-align: center;
}
.add-workshop-page .process-steps .carousel-step .index {
  font-weight: 600;
  background: var(--mariner-color);
  color: #fff;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
  margin: 0 auto;
}
.add-workshop-page .process-steps .carousel-step .title {
  margin: 5px auto 10px auto;
  color: #444;
}

@media (max-width: 580px) {
  .add-workshop-page .process-steps .ant-steps {
    display: none;
  }
  .add-workshop-page .process-steps .ant-carousel {
    display: block;
  }
}
/*=====  End of Process Steps  ======*/







/*=============================================
=               Workshop Details              =
=============================================*/
.add-workshop-page .cl-color-control,
.add-workshop-page .ant-form#workshop_setup {
  padding: 0 !important;
}


/*============  End of effects  =============*/





/*=============================================
=                Welcome Modal                =
=============================================*/
.add-workshop-welcome-modal {
  top: 91px;
  width: 720px !important;
}
.add-workshop-welcome-modal .ant-modal-content {
  border-radius: 10px;
  background: #FFF;
}
@media (max-width: 992px) {
  .add-workshop-welcome-modal {
    top: 113px;
    width: 95% !important;
    min-width: 320px !important;
  }
}
.add-workshop-welcome-modal .ant-modal-body {
  padding: 30px;
}
.add-workshop-welcome-modal h1 {
  color: var(--primary-color);
  text-align: center;
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
  margin-bottom: 20px;
}
.add-workshop-welcome-modal p {
  color: #444;
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 20px;
}
.add-workshop-welcome-modal .ant-modal-close {
  top: 20px;
  right: 20px;
  height: 20px;
  width: 20px;
}
.add-workshop-welcome-modal .ant-modal-close .ant-modal-close-x,
.add-workshop-welcome-modal .ant-modal-close svg {
  width: 20px;
  height: 20px;
  line-height: 20px;
}
.add-workshop-welcome-modal .ant-btn {
  width: 240px;
  height: 40px;
  margin: 30px auto 10px auto;
  display: block;
}
.add-workshop-welcome-modal .ant-btn span {
  font-weight: 600;
  font-size: 16px !important;
}

/*=========  End of Welcome Modal  ===========*/

