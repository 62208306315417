.my-coworkers-container {
  height: 100%;
  padding: 30px 0;
  width: 90%;
  max-width: 960px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  min-height: 94vh;
}
.my-coworkers-container .ck-navigation-button {
  margin: 0 0 0 -40px;
}
@media (max-width: 992px) {
  .my-coworkers-container .ck-navigation-button {
    margin-left: 0;
  }
}

.my-coworkers-container .header-options {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100% !important;
}
.my-coworkers-container .header-options .back-button {
  padding: 0px !important;
  color: var(--primary-color) !important;
}
.my-coworkers-container .header-options .back-button span {
  font-size: 16px !important;
  line-height: 16px !important;
  text-decoration: underline;
  font-family: "Emprint-semibold";
  margin: 0px 5px 0px 0px !important;
}
.my-coworkers-container .list-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  /* gap: 20px; */
  width: 100%;
  max-width: 960px;
  height: 100%;
}
.my-coworkers-container .add-coworker-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  /* gap: 20px; */
  width: 100%;
  max-width: 960px;
  padding-top: 20px;
}

@media only screen and (max-width: 992px) {
  .my-coworkers-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 100% !important;
    background-color: white !important;
    min-height: 94vh;
    padding: 20px 13.5px !important;
  }
}
