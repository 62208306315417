.my-coworkers-container .list-container .coworker-card-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  border: 0px solid #cccccc;
  border-bottom: 1px solid #cccccc !important;
  border-style: dashed !important;
  width: 100%;
  /* height: 280px !important; */
  /* min-height: 280px !important; */
  padding: 20px 0px !important;
}

.my-coworkers-container
  .list-container
  .coworker-card-container
  .cw-card-divisor {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  min-height: calc(100% - 50px) !important;
}
.my-coworkers-container
  .list-container
  .coworker-card-container
  .cw-card-divisor
  .cw-info {
  flex: 1 !important;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  height: 100%;
  gap: 20px !important;
  padding: 0px 0px 0px 0px;
}
.my-coworkers-container
  .list-container
  .coworker-card-container
  .cw-card-divisor
  .cw-info
  .cw-logo {
  width: 180px !important;
  height: 180px !important;
  border-radius: 6px;
  border: 1px solid #cccccc;
  background-color: white;
  object-fit: cover !important;
}
.my-coworkers-container
  .list-container
  .coworker-card-container
  .cw-card-divisor
  .cw-info
  .fallback-logo {
  border: 50px solid #d1d3d4;
  background-color: #d1d3d4;
}
.my-coworkers-container
  .list-container
  .coworker-card-container
  .cw-card-divisor
  .cw-info
  .btn-photo {
  width: 100% !important;
  border: none !important;
  box-shadow: none !important;
}
.my-coworkers-container
  .list-container
  .coworker-card-container
  .cw-card-divisor
  .cw-info
  .btn-photo
  span {
  font-size: 16px;
  line-height: 20px;
  font-family: "Emprint-semibold";
  color: var(--primary-color);
  margin: 0px 1.5px;
}
.my-coworkers-container
  .list-container
  .coworker-card-container
  .cw-card-divisor
  .cw-info
  .cw-info-container {
  flex: 1 !important;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  /* gap: 20px !important; */

  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.my-coworkers-container
  .list-container
  .coworker-card-container
  .cw-card-divisor
  .cw-info
  .cw-name {
  flex: 1 !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  border-radius: 10px !important;
  width: 100% !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.my-coworkers-container .list-container .coworker-card-container .name-wrapper {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.my-coworkers-container
  .list-container
  .coworker-card-container
  .cw-card-divisor
  .cw-info
  .cw-edit {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100% !important;

  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.my-coworkers-container
  .list-container
  .coworker-card-container
  .cw-card-divisor
  .cw-info
  .cw-edit
  .edit-link {
  display: flex;
  flex-direction: row;
  gap: 1px !important;

  font-size: 20px;
  line-height: 18px;
  font-family: "Emprint-semibold";
  color: var(--primary-color);

  border-bottom: 2px solid var(--primary-color) !important;
  margin: 0px 0px 5px 0px;
}
/* 
.my-coworkers-container
  .list-container
  .coworker-card-container
  .cw-card-divisor
  .cw-info
  .cw-edit
  .edit-link:hover {
  cursor: pointer !important;
  padding: 1px 0px 0px 1px !important;
  border-radius: 6px 6px 0px 0px !important;
  background-color: rgba(47, 102, 242, 0.2);
} 
*/
.my-coworkers-container
  .list-container
  .coworker-card-container
  .cw-card-divisor
  .cw-info
  .cw-edit
  .edit-link
  p {
  font-size: 14px;
  line-height: 18px;
  font-family: "Emprint-semibold";
  color: var(--primary-color);

  margin: 0px !important;
}
.my-coworkers-container
  .list-container
  .coworker-card-container
  .cw-card-divisor
  .cw-info
  .cw-edit
  .edit-link
  span {
  margin: 1px 0px -15px 0px !important;
}
/* 
.my-coworkers-container
  .list-container
  .coworker-card-container
  .cw-card-divisor
  .cw-info
  .cw-name:hover {
  background-color: #f7f7f7 !important;
  border: 1px solid #f7f7f7 !important;
  cursor: pointer !important;
} 
*/
.my-coworkers-container
  .list-container
  .coworker-card-container
  .cw-card-divisor
  .cw-info
  .cw-name
  .name {
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
  color: var(--primary-color);
  margin: 0px 0px 5px 0px;
}
/* 
.my-coworkers-container
  .list-container
  .coworker-card-container
  .cw-card-divisor
  .cw-info
  .cw-name
  .edit-icon:hover {
  border-radius: 6px !important;
  background-color: rgba(47, 102, 242, 0.2);
} 
*/
.my-coworkers-container
  .list-container
  .coworker-card-container
  .cw-card-divisor
  .cw-info
  .cw-name
  .desc {
  font-size: 16px;
  line-height: 20px;
  font-family: "Emprint-semibold";
  color: #444444;
  margin: 0px !important;
}

@media only screen and (max-width: 1100px) {
  .my-coworkers-container
    .list-container
    .coworker-card-container
    .cw-card-divisor
    .cw-info
    .cw-logo {
    width: 70px !important;
    height: 70px !important;
  }
  .my-coworkers-container
    .list-container
    .coworker-card-container
    .cw-card-divisor
    .cw-info
    .fallback-logo {
    border: 20px solid #d9d9d9;
  }
  .my-coworkers-container
    .list-container
    .coworker-card-container
    .cw-card-divisor
    .cw-info
    .btn-photo {
    padding: 0px !important;
  }
  .my-coworkers-container
    .list-container
    .coworker-card-container
    .cw-card-divisor
    .cw-info
    .btn-photo
    span {
    display: block;
    font-size: 14px !important;
    line-height: 16px !important;
  }
}
