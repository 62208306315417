.new-coworker-container {
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  gap: 5px 20px !important;
  margin-top: 10px !important;
}
.new-coworker-container .ant-form-item {
  margin: 0px !important;
  width: calc(50% - 10px) !important;
}
.new-coworker-container .ant-form-item.ck-checkbox {
  width: 100% !important;
}
.new-coworker-container .ant-form-item .ant-form-item-row {
  display: flex !important;
  flex-direction: column !important;
  justify-content: start;
  align-items: start;
  width: 100% !important;
}
.new-coworker-container .ant-form-item .ant-form-item-row .ant-form-item-label {
  text-align: start !important;
  width: 100%;
  padding: 0px !important;
  margin-bottom: -5px !important;
}
.new-coworker-container
  .ant-form-item
  .ant-form-item-row
  .ant-form-item-label
  label {
  font-size: 16px;
  line-height: 20px;
  color: #444444;
  font-family: "Emprint-semibold";
}
.new-coworker-container
  .ant-form-item
  .ant-form-item-row
  .ant-form-item-label
  label::before {
  font-size: 16px;
  line-height: 20px;
  color: #444444;
  font-family: "Emprint-semibold";
  margin: 0px !important;
}
.new-coworker-container
  .ant-form-item
  .ant-form-item-row
  .ant-form-item-label
  label::after {
  display: none !important;
}
.new-coworker-container
  .ant-form-item
  .ant-form-item-row
  .ant-form-item-control {
  width: 100%;
}
/* .new-coworker-container
  .ant-form-item
  .ant-checkbox-wrapper
  .ant-checkbox
  span {
  width: 16px;
  height: 16px;
} */
.new-coworker-container .ant-form-item .ant-checkbox-wrapper span {
  color: #444444;
  font-size: 16px;
  font-family: "EMprint-Semibold";
}
.new-coworker-container .ant-form-item input {
  border-radius: 0px !important;
  padding: 0px !important;
  border: none !important;
  border-bottom: 1px solid #444444 !important;
}
.new-coworker-container .ant-form-item .ant-input-disabled {
  border: none !important;
  background-color: white !important;
  color: #444444 !important;
}
.new-coworker-container .ant-form-item .ant-select-selector {
  border-radius: 0px !important;
  padding: 3px 0px 0px 0px !important;
  border: 0px;
  border-bottom: 1px solid #444444 !important;
  margin-top: -6px !important;
}
.new-coworker-container
  .ant-form-item
  .ant-select-disabled
  .ant-select-selector {
  border: none !important;
  background-color: white !important;
  color: #444444 !important;
}
.new-coworker-container .ant-form-item .ant-select-disabled .ant-select-arrow {
  display: none !important;
}
.new-coworker-container .ant-form-item .ant-select-arrow {
  margin-right: -8px !important;
  color: #444444 !important;
}
.new-coworker-container .ant-form-item .ant-picker {
  width: 100% !important;
  border: none !important;
  padding: 0px !important;
  border-radius: 0px !important;
}
.new-coworker-container .ant-form-item .ant-picker-disabled {
  border: none !important;
  background-color: white !important;
  color: #444444 !important;
}
.new-coworker-container
  .ant-form-item
  .ant-picker-disabled
  .ant-picker-input
  input {
  border: none !important;
}
.new-coworker-container .ant-form-item .ant-picker-disabled .ant-picker-suffix {
  display: none !important;
}
.new-coworker-container .ant-form-item .ant-picker .ant-picker-suffix {
  border: none !important;
  border-bottom: 1px solid #444444 !important;
  padding: 5px 0px 4px 5px !important;
  margin: 0px !important;
}
.new-coworker-container .ant-form-item .ant-picker .ant-picker-suffix {
  color: #444444 !important;
}
.new-coworker-options {
  flex: 1 !important;
  display: flex;
  flex-direction: row !important;
  justify-content: flex-end;
  align-items: center;
  gap: 20px !important;
  margin-top: 20px !important;
}
.new-coworker-options .primary-buttons-container {
  flex: 1 !important;
  display: flex;
  flex-direction: row !important;
  justify-content: flex-end;
  align-items: center;
  gap: 20px !important;
}
/* .new-coworker-options .primary-buttons-container .edit-button {
  background-color: white !important;
  border: 2px solid var(--primary-color) !important;
}
.new-coworker-options .primary-buttons-container .edit-button span {
  color: var(--primary-color) !important;
} */
.new-coworker-options .ant-btn-link {
  width: 98.2px !important;
  height: 40px !important;
  padding: 5px 0px !important;
}
.new-coworker-options .ant-btn-link span {
  font-size: 16px !important;
  text-decoration: underline !important;
}

@media only screen and (max-width: 1140px) {
  .new-coworker-options {
    flex-direction: column-reverse !important;
    justify-content: center;
    align-items: center;
    width: calc(100% + 90px) !important;
    margin-left: -90px;
    gap: 10px !important;
  }
  .new-coworker-options .primary-buttons-container {
    flex-direction: column-reverse !important;
    gap: 20px !important;
  }
}
@media only screen and (max-width: 1100px) {
  .new-coworker-container .ant-form-item {
    margin: 0px !important;
    width: 100% !important;
  }
  .new-coworker-container .force-size {
    width: calc(50% - 10px) !important;
  }

}
@media only screen and (max-width: 450px) {
  .new-coworker-options {
    justify-content: center;
    align-items: center;
    width: calc(100% + 90px) !important;
    margin-left: -90px;
    /* gap: 25px !important; */
  }
}
